<!-- begin:: Content -->
<router-outlet />
<!-- end:: Content -->

<!-- begin:: Cookie Popup -->
<app-cookie-popup />
<!-- end:: Cookie Popup -->

<!-- begin:: Zendesk Contact Form Widget -->
<app-zendesk-widget />
<!-- end:: Zendesk Contact Form Widget -->

<!-- begin:: Google Analytics 4 -->
<app-google-analytics />
<!-- end:: Google Analytics 4 -->