// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Guard
import { LangRouteGuard } from './lang-route.guard';
// Components
import { ShellComponent } from './views/shell.component';
// Constants
import { APP_CONSTANT } from '@constants';

export const routes: Routes = [
  {
    path: ':lang',
    canActivate: [LangRouteGuard],
    component: ShellComponent,
    loadChildren: () => import('./views/shell.module').then(m => m.ShellModule)
  },
  {
    path: "",
    canActivate: [LangRouteGuard],
    children: [],
    pathMatch: "full",
  },
  {
    path: '**',
    redirectTo: `${APP_CONSTANT.DFAULT_LOCALE}/error/404`
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    enableViewTransitions: true
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
