// Angular
import { NgModule, APP_INITIALIZER, ErrorHandler, APP_ID } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { CommonModule, DOCUMENT } from '@angular/common';
// APP_INIT
import { APP_INIT_PROVIDER } from './app-init-loader.service';
// Modules
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CoreModule } from './core/core.module';
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox';
// App Routing
import { AppRoutingModule } from './app-routing.module';
// Components
import { AppComponent } from './app.component';
// Environments
import { environment } from '@environment';
import { LOCALHOST_URL } from '@constants';
// State
import { metaReducers, reducers } from './core/reducers';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';
// Core Utils
import {
  HttpUtilsService,
  TypesUtilsService,
  GlobalErrorHandler,
  preloadI18nLocale,
  LocaleProvider,
  RecaptchaLocaleProvider,
  RecaptchaKeyProvider,
  GEO_IP_INIT_PROVIDER
} from 'src/app/core/_base/utils';
// Material
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// Transloco Module
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoService } from '@jsverse/transloco';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot({ baseUrl: environment.production ? `${environment.baseUrl}/` : `${LOCALHOST_URL}/` }),
    TranslocoRootModule,
    CommonModule,
    CoreModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    AuthModule.forRoot()
  ],
  exports: [],
  providers: [
    provideClientHydration(),
    provideHttpClient(withJsonpSupport(), withFetch(), withInterceptorsFromDi()),
    APP_INIT_PROVIDER,
    AuthService,
    { provide: APP_ID, useValue: 'serverApp' },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadI18nLocale,
      deps: [TranslocoService, DOCUMENT]
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'center', // Set the horizontal position (start, center, end)
        verticalPosition: 'bottom',  // Set the vertical position (top, bottom)
      } as MatSnackBarConfig,
    },
    LocaleProvider,
    RecaptchaLocaleProvider,
    RecaptchaKeyProvider,
    GEO_IP_INIT_PROVIDER,
    environment.isSentryLoggingEnable ? { provide: ErrorHandler, useClass: GlobalErrorHandler } : [],
    // Services
    HttpUtilsService,
    TypesUtilsService,
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false,
        exitAnimationTime: 1000,
        panelClass: 'fullscreen'
      } as LightboxConfig
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }