// Angular
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
// RxJS
import { Subscription, filter } from "rxjs";
// Services
import { SeoService } from "./core/_base/utils/seo.service";
import { ThemeModeService } from './views/pages/layout/services';
import { CommonService } from '@core/utils';
// Environments
import { environment } from "@environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

  // Private
  private subscriptions: Subscription[] = [];

  /**
   * Creates an instance of AppComponent.
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {SeoService} seoService
   * @param {ThemeModeService} themeModeService
   * @param {CommonService} commonService
   * @param {Document} document
   * @memberof AppComponent
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private themeModeService: ThemeModeService,
    private readonly commonService: CommonService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setFavicon();
  }

  /**
   * On Init
   * @memberof AppComponent
   */
  ngOnInit() {
    this.themeModeService.init();
    this.setPageTitleOnNavigation();
  }

  /**
   * Set Favicon
   * @private
   * @memberof AppComponent
   */
  private setFavicon(): void {
    const favicon: HTMLLinkElement = this.document.querySelector('#appFavicon') as HTMLLinkElement;
    favicon.setAttribute('href', environment.favicon);
  }

  /**
   * Set Page Title OnNavigation
   * @private
   * @memberof AppComponent
   */
  private setPageTitleOnNavigation(): void {
    const routeEventSubscription = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.updatePageTitle());
    this.subscriptions.push(routeEventSubscription);
  }
  /**
   * Update Page Title
   * @private
   * @memberof AppComponent
   */
  private updatePageTitle(): void {
    const route = this.getLastChildRoute(this.activatedRoute.snapshot);
    const skipDefaultTitle = route?.data?.skipDefaultTitle;

    if (!skipDefaultTitle) {
      this.seoService.setPage(route?.data?.title);
    }
  }

  /**
   * Get Last Child Route
   * @private
   * @param {*} route
   * @returns {*}
   * @memberof AppComponent
   */
  private getLastChildRoute(route: any): any {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  /**
   * OnDestroy
   * @memberof AppComponent
   */
  ngOnDestroy(): void {
    this.commonService.closeLiveSharedData();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
