// Angular
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
// Constants
import { APP_CONSTANT, ENV_CUSTOMER_LANG, LOCALES } from '@constants';
// Rxjs
import { delay, map } from 'rxjs/operators';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Services
import { CommonService } from '@core/utils';
import { AppInitLoaderService } from './app-init-loader.service';
import { LayoutService } from './views/pages/layout/services';

@Injectable({ providedIn: 'root' })
export class LangRouteGuard implements CanActivate {

  /**
   * Creates an instance of LangRouteGuard.
   * @param {Router} router
   * @param {TranslocoService} translocoService
   * @param {CommonService} commonService
   * @param {LayoutService} layoutService
   * @param {AppInitLoaderService} appInitLoaderService
   * @param {Object} platformId
   * @memberof LangRouteGuard
   */
  constructor(
    private readonly router: Router,
    private readonly translocoService: TranslocoService,
    private readonly commonService: CommonService,
    private readonly layoutService: LayoutService,
    private readonly appInitLoaderService: AppInitLoaderService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  /**
   * Canactive function
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof LangRouteGuard
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      return this.appInitLoaderService.appInitDoneSubject.pipe(
        map(() => {
          const availableLangs: Array<string> = this.translocoService.getAvailableLangs() as Array<string>;
          const lang = route.paramMap.get('lang');

          if (lang !== null) {
            // The language is not null, proceed with the logic
            if (availableLangs.includes(lang)) {
              this.translocoService.setActiveLang(lang);

              const savedLanguage = this.commonService.getFromLocalStorage(ENV_CUSTOMER_LANG);

              if (lang !== savedLanguage) {
                const locale = LOCALES.find(item => item.locale === lang);
                this.layoutService.setCustomerLanguage(locale);
              }
            } else {
              this.handleInvalidLanguage();
            }
          } else {
            // Handle the case where 'lang' is null and redirect to a default language or saved
            this.handleInvalidLanguage();
          }

          return true;
        }),
        delay(200)
      );
    } catch (error) {
      console.log('lang-canactive-route-error', error);
      return true;
    }
  }

  /**
   * Handle Invalid Language Route
   * @private
   * @param {string} lang
   * @memberof LangRouteGuard
   */
  private handleInvalidLanguage(): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const language = this.commonService.getFromLocalStorage(ENV_CUSTOMER_LANG);

        if (language && language !== APP_CONSTANT.DFAULT_LOCALE) {
          this.router.navigateByUrl(`/${language}/refresh`, { skipLocationChange: true }).then(() => {
            window.location.href = `/${language}`;
          });
        } else {
          this.router.navigateByUrl(`/${APP_CONSTANT.DFAULT_LOCALE}`);
        }
      } else {
        this.router.navigateByUrl(`/${APP_CONSTANT.DFAULT_LOCALE}`);
      }
    } catch (error) {
      console.log('lang-route-error', error)
    }
  }
}
